<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>
    <b-button
      @click="$router.go(-1)"
      variant="outline-warning"
      class="btn-icon my-1"
      size="sm"
    >
      <span class="">RETOUR</span>
    </b-button>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>
    <div :hidden="!hiddenLoader">
      <div class="row">
        <b-card class="container-informations ml-1" style="width: 30%">
          <b-card-title class="h4">
            INFORMATIONS
            <b-badge
              class="float-right"
              style="font-size: 0.5em"
              :variant="manifeste.status ? 'primary' : 'warning'"
              >{{
                manifeste.status ? "Receptionné" : "Nom receptionné"
              }}</b-badge
            >
          </b-card-title>
          <div class="row mt-2 pl-1 border-top">
            <b-card class="mb-0 block-text block-text-media">
              <div class="row mb-2">
                <b-icon-arrow-clockwise
                  class="h3 text-primary"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-arrow-clockwise>
                <b-card-text>
                  <span style="color: #b4b7bd">NUMERO MANIFESTE</span> <br />
                  <span
                    class="cursor"
                  >
                    {{
                      manifeste.num_manifeste ? manifeste.num_manifeste : "-------"
                    }}
                  </span>
                </b-card-text>
              </div>
              <div class="row mb-2">
                <b-icon-building
                  class="h3 text-primary"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-building>
                <b-card-text>
                  <span style="color: #b4b7bd">ASSOCIATION</span> <br />
                  <span
                    class="cursor"
                    @click="showAssociation(manifeste.association)"
                  >
                    {{
                      manifeste.association ? manifeste.association.name : ""
                    }}
                  </span>
                </b-card-text>
              </div>
              <div class="row mb-2">
                <b-icon-layers
                  class="h3 text-primary"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-layers>
                <b-card-text>
                  <span style="color: #b4b7bd">COMPTE</span> <br />
                  <span class="cursor" @click="showCompte(manifeste.compte)">
                    {{
                      manifeste.compte ? manifeste.compte.libelle : "-----"
                    }}</span
                  >
                </b-card-text>
              </div>
              <div class="row mb-2">
                <b-icon-people
                  class="h3"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-people>
                <b-card-text>
                  <span style="color: #b4b7bd">CHARGEUR</span> <br />
                  <span
                    class="cursor"
                    @click="showChargeur(manifeste.chargeur)"
                  >
                    {{
                      manifeste.chargeur ? manifeste.chargeur.name : "-----"
                    }}</span
                  >
                </b-card-text>
              </div>
              <div class="row mb-2">
                <b-icon-arrow-clockwise
                  class="h3"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-arrow-clockwise>
                <b-card-text>
                  <span style="color: #b4b7bd">NUMERO VOYAGE</span> <br />
                  {{ manifeste.num_voyage ? manifeste.num_voyage : "-----" }}
                </b-card-text>
              </div>
              <div class="row mb-2">
                <b-icon-option
                  class="h3"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-option>
                <b-card-text>
                  <span style="color: #b4b7bd">NAVIRE</span> <br />
                  {{ manifeste.navire ? manifeste.navire.name : "-----" }}
                </b-card-text>
              </div>
              <div class="row mb-2">
                <b-icon-option
                  class="h3"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-option>
                <b-card-text>
                  <span style="color: #b4b7bd">TRANSPORT PAYE PAR</span> <br />
                  {{
                    manifeste.association_paye_transport
                      ? "ASSOCIATION"
                      : "CHARGEUR"
                  }}
                </b-card-text>
              </div>
              <div class="row mb-2">
                <b-icon-calendar-event
                  class="h3 text-primary"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-calendar-event>
                <b-card-text>
                  <span style="color: #b4b7bd">DATE D'ARRIVEE</span> <br />
                  {{
                    manifeste.date_arrivee ? manifeste.date_arrivee : "-----"
                  }}
                </b-card-text>
              </div>
              <div class="row">
                <b-icon-truck
                  class="h3"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-truck>
                <b-card-text>
                  <span style="color: #b4b7bd">NOMBRE DE VÉHICULES</span> <br />
                  {{ nbr_voitures_vendues }} vendu(s) /
                  {{ manifeste.nbr_vehicules }}
                </b-card-text>
              </div>
            </b-card>
          </div>
        </b-card>

        <div class="col">
          <b-card class="container-manifeste pb-5 ml-1">
            <b-card-title>
              <span class="h4">MANIFESTE N° {{ manifeste.numero_manif }}</span>
              <b-button
                size="sm"
                variant="outline-success"
                @click="printManifeste()"
                class="float-right mr-1"
              >
                <b-icon-printer font-scale="1"></b-icon-printer>
                <span style="margin-left: 10px">Imprimer</span>
              </b-button>
              <b-button
                  v-if="
                  !manifeste.status
                "
                  size="sm"
                  variant="outline-danger"
                  @click="deleteManifeste(manifeste.id)"
                  class="float-right mr-1"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
              <b-button
                v-if="
                  !manifeste.status && !manifeste.enregistrement_stock_initial
                "
                size="sm"
                variant="outline-secondary"
                @click="editManifeste"
                class="float-right mr-1"
              >
                <b-icon-pencil font-scale="1"></b-icon-pencil>
                <span style="margin-left: 10px">Modifier</span>
              </b-button>

              <b-button
                v-if="!manifeste.status"
                size="sm"
                variant="outline-primary"
                @click="editDateArriveeManifeste"
                class="float-right mr-1"
              >
                <b-icon-check font-scale="1"></b-icon-check>
                <span>Receptionner</span>
              </b-button>
            </b-card-title>

            <div class="row mt-2 pl-3 pt-2 border-top" style="width: 100%">
              <b-table-simple
                hover
                caption-top
                responsive
                class="rounded-bottom mb-0"
              >
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>ÉLÉMENT</b-th>
                    <b-th>MONTANT</b-th>
                    <b-th>TAUX</b-th>
                    <b-th>CONVERSION</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td rowspan="1"> ACHAT </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.prix_achat) }}
                      {{ manifeste.dev_achat }}
                    </b-td>
                    <b-td>
                      <b-form-input
                          :disabled="manifeste.status===1 || devAchat===1"
                          id="devise_transport"
                          v-model="devAchat"
                          @input="updateTaux()"
                          type="text"
                          class="col mr-1"/>
                    </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.conv_achat) }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td rowspan="1"> TRANSPORT </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.prix_transport) }}
                      {{ manifeste.dev_transport }}
                    </b-td>
                    <b-td>
                      <b-form-input
                          :disabled="manifeste.status===1 || devTransp===1"
                          id="devise_transport"
                          v-model="devTransp"
                          @input="updateTaux()"
                          type="text"
                          class="col mr-1"/>
                    </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.conv_transport) }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td rowspan="1"> CARTE IMPORTATEUR </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.prix_carte_imp) }}
                      {{ manifeste.dev_carte_imp }}
                    </b-td>
                    <b-td>
                      <b-form-input
                          :disabled="manifeste.status===1 ||devImpo===1"
                          id="devise_transport"
                          v-model="devImpo"
                          @input="updateTaux()"
                          type="text"
                          class="col mr-1"/>
                    </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.conv_carte_imp) }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td rowspan="1"> FRAIS </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.frais) }}
                      {{ manifeste.dev_frais }}
                    </b-td>
                    <b-td>
                      <b-form-input
                          :disabled="manifeste.status===1 ||devFrais===1"
                          id="devise_transport"
                          v-model="devFrais"
                          @input="updateTaux()"
                          type="text"
                          class="col mr-1"/>
                    </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.conv_frais) }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                  </b-tr>
                  <!-- <b-tr
                    v-if="
                      manifeste.acheteurassocie &&
                      manifeste.acheteurassocie.type == 'acheteur'
                    "
                  >
                    <b-td rowspan="1"> COMMISSION ACHETEUR </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.commission_acheteur) }}
                      {{ manifeste.dev_achat }}
                    </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.conv_commission) }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                  </b-tr> -->
                  <b-tr>
                    <b-td rowspan="1"> TOTAL DES ACHATS </b-td>
                    <b-td>
                      {{ $thousandSeparator(totalAchats) }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                    <b-td> </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td rowspan="1"> DEPENSES TOTALES </b-td>
                    <b-td>
                      {{ $thousandSeparator(manifeste.total_depenses) }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                    <b-td> </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td rowspan="1"> PRIX TOTAL DE REVIENT </b-td>
                    <b-td>
                      {{
                        manifeste.prix_revient
                          ? $thousandSeparator(
                              manifeste.prix_revient -
                                manifeste.total_demarcheurs
                            )
                          : 0
                      }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                    <b-td> </b-td>
                  </b-tr>
                  <b-tr v-if="nbr_voitures_vendues > 0">
                    <b-td rowspan="1"> PRIX DE VENTE TOTAL </b-td>
                    <b-td>
                      {{ $thousandSeparator(totalPrixVente) }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                    <b-td> </b-td>
                  </b-tr>
                  <b-tr
                    v-if="manifeste.status == 1 && nbr_voitures_vendues > 0"
                  >
                    <b-td rowspan="1"> BENEFICE TOTAL </b-td>
                    <b-td>
                      {{ $thousandSeparator(checkManifesteBenefice) }}
                      {{ manifeste.dev_conversion }}
                    </b-td>
                    <b-td> </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-card>
        </div>
      </div>
      <div :hidden="hidden">
        <b-card class="mt-1" title="">
          <div class="border-bottom pb-2">
            <span class="h4" style="margin-top: 5px">LISTE DES VOITURES</span>
          </div>

          <b-card no-body title="" class="mb-0">
            <div class="my-2 row">
              <!-- search start -->
              <div class="container-search search ml-1" style="width: 25%">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Recherche"
                  class="col4 mr-5"
                >
                </b-form-input>
              </div>
              <!-- search end -->

              <!-- bouton add start -->
              <div class="ml-auto mr-1">
                <b-button
                  variant="primary"
                  v-b-modal.modal-add-vehicule
                  v-if="!manifeste.status"
                  @click="addVehicule"
                >
                  AJOUTER UNE VOITURE
                </b-button>
              </div>
              <!-- bouton add end -->
            </div>

            <!-- table start -->
            <b-table
              id="my-table"
			  class="position-relative"
              responsive
              :fields="headers"
              :items="vehicules"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              show-empty
            >
              <template #cell(numero)="data">
                {{ index(data.item) }}
              </template>

              <template #cell(description)="data">
                <span>
                  {{ data.item.marque }} - {{ data.item.couleur }} -
                  {{ data.item.annee }}
                </span>
              </template>
              <template #cell(numero_chassis)="data">
                <b-button size="sm" variant="info" @click="showItem(data.item)">
                  {{ data.item.numero_chassis }}
                </b-button>
              </template>
              <template #cell(depenses)="data">
                {{
                  data.item.depenses
                    ? $thousandSeparator(data.item.depenses)
                    : "-------"
                }}
              </template>
              <template #cell(prix_achat)="data">
                {{
                  data.item.prix_achat
                    ? $thousandSeparator(data.item.prix_achat)
                    : "-------"
                }}
              </template>
              <template #cell(prix_transport)="data">
                {{
                  data.item.prix_transport
                    ? $thousandSeparator(data.item.prix_transport)
                    : "-------"
                }}
              </template>
              <template #cell(prix_vente)="data">
                {{
                  data.item.prix_vente
                    ? $thousandSeparator(data.item.prix_vente)
                    : "-------"
                }}
              </template>
              <template #cell(prix_carte_imp)="data">
                {{
                  data.item.prix_carte_imp
                    ? $thousandSeparator(data.item.prix_carte_imp)
                    : "-------"
                }}
              </template>
              <template #cell(frais)="data">
                {{
                  data.item.frais
                    ? $thousandSeparator(data.item.frais)
                    : "-------"
                }}
              </template>
              <template #cell(benefice)="data">
                {{
                  data.item.benefice
                    ? $thousandSeparator(data.item.benefice)
                    : "-------"
                }}
              </template>
              <template #cell(statut)="data">
                <b-badge>
                  {{ data.item.statut }}
                </b-badge>
              </template>
              <template #cell(total_dep)="data">
                {{
                  data.item.total_dep
                    ? $thousandSeparator(data.item.total_dep)
                    : "-------"
                }}
              </template>
              <template #cell(total_dem)="data">
                {{
                  data.item.total_dem
                    ? $thousandSeparator(data.item.total_dem)
                    : "-------"
                }}
              </template>
              <template #cell(prix_revient)="data">
                {{
                  data.item.prix_revient
                    ? $thousandSeparator(data.item.prix_revient)
                    : "-------"
                }}
              </template>
              <template #cell(total_achat)="data">
                {{
                  data.item.total_achat
                      ? $thousandSeparator(data.item.total_achat)
                      : "-------"
                }}
              </template>
              <template #cell(montant_vente)="data">
                {{ $thousandSeparator(data.item.montant_vente) }}
              </template>
              <template #cell(avance_percue)="data">
                {{ $thousandSeparator(data.item.avance_percue) }}
              </template>
              <template #cell(reste_a_paye)="data">
                {{ $thousandSeparator(data.item.reste_a_paye) }}
              </template>
              <template #cell(montant_paye)="data">
                {{ $thousandSeparator(data.item.montant_paye) }}
              </template>

              <template #cell(actions)="data">
                <div class="row justify-content-center">
                  <div>
                    <b-button
                      :id="'button-1' + data.item.id"
                      v-b-modal.modal-edit-vehicule
                      v-if="
                        manifeste.status == 0 &&
                        $hasFonction(user, 'Modifier véhicule')
                      "
                      size="sm"
                      variant="flat-warning"
                      class="btn-icon"
                      @click="editVehicule(data.item)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <!-- <b-tooltip
                      :target="'button-1' + data.item.id"
                      title="Editer"
                      variant="dark"
                    ></b-tooltip> -->
                  </div>

                  <div>
                    <b-button
                      :id="'button-2' + data.item.id"
                      v-if="
                        manifeste.status == 0 &&
                        $hasFonction(user, 'Supprimer véhicule')
                      "
                      size="sm"
                      variant="flat-danger"
                      class="btn-icon"
                      @click="deleteVehicule(data.item)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <!-- <b-tooltip
                      :target="'button-2' + data.item.id"
                      title="Supprimer"
                      variant="dark"
                    ></b-tooltip> -->
                  </div>
                </div>
              </template>

              <template #empty>
                <div class="text-center">
                  <span class="text-nowrap">Aucune donnée existante</span>
                </div>
              </template>

              <template #emptyfiltered>
                <div class="text-center">
                  <span class="text-nowrap"
                    >L'élément recherché n'est pas dans la liste</span
                  >
                </div>
              </template>
            </b-table>
            <!-- table end -->

            <div class="my-2 mx-2 row">
              <!-- per-page end -->
              <div class="col">
                <span class="text-nowrap mr-2"> Montré de 1 à </span>
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  style="width: 70px"
                ></b-form-select>
                <span class="text-nowrap ml-2">
                  de {{ totalRows }} entrées
                </span>
              </div>
              <!-- per-page end -->

              <!-- pagination start -->
              <b-pagination
                class="pagination"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                aria-controls="my-table"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
              <!-- pagination end -->
            </div>
          </b-card>
        </b-card>

        <!-- modal edit vehicule start  -->
        <b-modal
          size="lg"
          id="modal-edit-vehicule"
          v-model="modalEditVehicule"
          title="Modifier information voiture"
          :ok-disabled="isOperating"
          @hidden="resetModalEditVehicule"
          hide-footer
          centered
        >
          <!-- form start -->
          <b-form
            class="mt-1"
            ref="editForm"
            @submit.stop.prevent="editHandleSubmit"
          >
            <b-row>
              <b-form-group class="col">
                <validation-provider
                  #default="{ errors }"
                  name="numero_chassis"
                  rules="required"
                >
                  <label for="numero_chassis">Numero chassis</label>
                  <b-form-input
                    id="numero_chassis"
                    type="text"
                    v-model="editVehiculeForm.numero_chassis"
                    placeholder="Numero chassis"
                    @click="resetInputNumChassisModalEdit"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsNumChassisModalEdit
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="col">
                <validation-provider
                  #default="{ errors }"
                  name="marque"
                  rules="required"
                >
                  <label for="marque">Marque</label>
                  <b-form-select
                    id="marque"
                    v-model="editVehiculeForm.marque"
                    :options="marquesList"
                    value-field="id"
                    text-field="name"
                    @click="resetInputMarqueModalEdit"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsMarqueModalEdit
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group class="col">
                <validation-provider
                  #default="{ errors }"
                  name="couleur"
                  rules="required"
                >
                  <label for="couleur">Couleur</label>
                  <b-form-select
                    id="couleur"
                    v-model="editVehiculeForm.couleur"
                    :options="couleursList"
                    value-field="id"
                    text-field="name"
                    @click="resetInputCouleurModalEdit"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsCouleurModalEdit
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="col">
                <validation-provider
                  #default="{ errors }"
                  name="annee"
                  rules="required"
                >
                  <label for="annee">Annee</label>
                  <b-form-input
                    id="annee"
                    type="text"
                    v-model="editVehiculeForm.annee"
                    placeholder="Annee"
                    @click="resetInputAnneeModalEdit"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsAnneeModalEdit
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group class="col">
                <validation-provider
                  #default="{ errors }"
                  name="prix_achat"
                  rules="required"
                >
                  <label for="prix_achat">Prix d'achat</label>
                  <cleave
                    class="form-control"
                    :raw="false"
                    id="prix_achat"
                    placeholder="0"
                    :options="numberFormat"
                    v-model="editVehiculeForm.prix_achat"
                    @click="resetInputPrixAchatModalEdit"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsPrixAchatModalEdit
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="col">
                <validation-provider
                  #default="{ errors }"
                  name="prix_transport"
                  rules="required"
                >
                  <label for="prix_transport">Prix de transport</label>

                  <cleave
                    class="form-control"
                    :raw="false"
                    id="prix_transport"
                    placeholder="0"
                    :options="numberFormat"
                    v-model="editVehiculeForm.prix_transport"
                    @click="resetInputPrixTransportModalEdit"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsPrixTransportModalEdit
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group class="col">
                <validation-provider
                  #default="{ errors }"
                  name="prix_carte_importateur"
                  rules="required"
                >
                  <label for="prix_carte_importateur"
                    >Prix carte importateur</label
                  >

                  <cleave
                    class="form-control"
                    :raw="false"
                    id="prix_carte_imp"
                    :options="numberFormat"
                    placeholder="0"
                    v-model="editVehiculeForm.prix_carte_imp"
                    @click="resetInputPrixCarteImpModalEdit"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsPrixCarteImpModalEdit
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="col">
                <validation-provider
                  #default="{ errors }"
                  name="frais"
                  rules="required"
                >
                  <label for="frais">Frais</label>
                  <cleave
                    class="form-control"
                    :raw="false"
                    id="frais"
                    placeholder="0"
                    :options="numberFormat"
                    v-model="editVehiculeForm.frais"
                    @click="resetInputFraisModalEdit"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsFraisModalEdit
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="modalEditVehicule = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonEditCar"
                variant="primary"
                class="mt-2"
                @click="editHandleOk"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal edit vehicule end-->
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button v-b-modal.modal-add-vehicule variant="primary" size="sm">
          <b-icon-plus></b-icon-plus>
          AJOUTER UNE VOITURE
        </b-button>
      </div>

      <!-- modal add date arrivee start  -->
      <b-modal
        id="modal-add-date-arrivee"
        v-model="modalEditDateArriveeManifeste"
        title="Ajouter une date d'arrivée"
        :ok-disabled="isOperating"
        @hidden="resetModalAddDateArrivee"
        hide-footer
        centered
      >
        <!-- form start -->
        <b-form
          class="mt-1"
          ref="addDateForm"
          @submit.stop.prevent="addDateHandleSubmit"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="date_arrivee"
              rules="required"
            >
              <label for="reccm">Date d'arrivée</label>
              <b-form-input
                      v-model="editedDate.date_arrivee"
                      :date-format-options="dateFormatOptions"
                      type="date"
                      @input="resetInputDate()"
                      class="d-inline-block"
              /> 
              <small class="text-danger">{{
                errors[0] ? errors[0] : errorsDateArriveeModalAdd
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="mb-0">
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="modalEditDateArriveeManifeste = false"
            >
              Annuler
            </b-button>

            <b-button
              v-if="etatButtonArrivee"
              variant="primary"
              class="mt-2"
              @click="addDateHandleOk"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </b-form-group>
        </b-form>
        <!-- form end -->
      </b-modal>
      <!-- modal add date arrivee end -->

      <!-- modal edit manifeste start -->
      <b-modal
        id="modal-edit-manifeste"
        v-model="modalEditManifeste"
        title="Modifier un manifeste"
        :ok-disabled="isOperating"
        @hidden="resetModalEditManifeste"
        hide-footer
        centered
      >
        <!-- form start -->
        <b-form
          class="mt-1"
          ref="editManifesteForm"
          @submit.stop.prevent="editManifesteHandleSubmit"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="societe"
              rules="required"
            >
              <label for="societe">Societe</label>
              <b-form-select
                :disabled="societesList.length == 1"
                id="societe"
                v-model="editManifesteForm.societeField"
                :options="societesList"
                value-field="id"
                text-field="name"
                @click="resetSelectSocieteModalEditManifeste"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{
                errors[0] ? errors[0] : errorsSocieteModalEditManifeste
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="association"
              rules="required"
            >
              <b-form-group>
                <label for="num_manifeste">Numéro manifeste</label>
                <b-form-input
                    id="num_manifeste"
                    type="text"
                    placeholder="DE78596"
                    v-model="editManifesteForm.num_manifeste"/>
              </b-form-group>
              <label for="association">Association</label>
              <b-form-select
                id="association"
                v-model="editManifesteForm.associationField"
                :options="associationsList"
                value-field="id"
                text-field="name"
                @click="resetSelectAssociationModalEditManifeste"
                @change="selectAssociationModalEditManifeste"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{
                errors[0] ? errors[0] : errorsAssociationModalEditManifeste
              }}</small>
            </validation-provider>
          </b-form-group>

          <!--  <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="associes_acheteur"
              rules="required"
            >
              <label for="associes_acheteur">Associé/Acheteur</label>
              <b-form-select
                id="associes_acheteur"
                v-model="editManifesteForm.associeField"
                :options="associes_acheteurList"
                value-field="id"
                text-field="name"
                @click="resetSelectAssociesAcheteurModalEditManifeste"
                @change="selectAssociesAcheteurModalEditManifeste"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{
                errors[0] ? errors[0] : errorsAssociesAcheteurModalEditManifeste
              }}</small>
            </validation-provider>
          </b-form-group> -->
          <b-form-group
            v-if="compte != null && compte.author_type == 'acheteur'"
          >
            <validation-provider
              #default="{ errors }"
              name="commission"
              rules="required"
            >
              <label for="navire">Commission acheteur</label>
              <cleave
                class="form-control"
                :raw="false"
                id="commission"
                :options="numberFormat"
                v-model="editManifesteForm.commissionField"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{
                errors[0] ? errors[0] : errorsCommissionModalEditManifeste
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="navire"
              rules="required"
            >
              <label for="navire">Comptes</label>
              <model-list-select
              :list="comptesList"
              id="compte"
              v-model="editManifesteForm.compteField"
              option-value="id"
              option-text="libelle"
                @click="resetSelectCompteModalEditManifeste"
                @change="selectCompteModalEditManifeste"
              :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{
                errors[0] ? errors[0] : errorsCompteModalEditManifeste
              }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group >
            <label for="num_voyage">Numéro Voyage</label>
            <b-form-input
                id="num_voyage"
                type="text"
                placeholder="A78596"
                v-model="editManifesteForm.num_voyage"/>
          </b-form-group>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="navire"
              rules="required"
            >
              <label for="navire">Navires</label>
              <model-list-select
              :list="naviresList"
              id="navire"
              v-model="editManifesteForm.navireField"
              option-value="id"
              option-text="name"
                @click="resetSelectNavireModalEditManifeste"
              :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{
                errors[0] ? errors[0] : errorsNavireModalEditManifeste
              }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <validation-provider
                #default="{ errors }"
                name="chargeur"
                rules="required"
            >
              <label for="chargeur">Chargeurs</label>
              <model-list-select
                  :list="chargeurs"
                  id="compte"
                  v-model="editManifesteForm.chargeurField"
                  option-value="id"
                  option-text="name"
                  @change="resetSelectChargeurModalEditManifeste"
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{
                  errors[0] ? errors[0] : errorsChargeurModalEditManifeste
                }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="modalEditManifeste = false"
            >
              Annuler
            </b-button>

            <b-button
              v-if="etatButtonEditManifeste"
              variant="primary"
              class="mt-2"
              @click="editManifesteHandleOk"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </b-form-group>
        </b-form>
        <!-- form end -->
      </b-modal>
      <!-- modal edit manifeste end-->

      <!-- modal add vehicule start  -->
      <b-modal
        id="modal-add-vehicule"
        size="lg"
        v-model="modalAddVehicule"
        title="Ajouter une voiture"
        :ok-disabled="isOperating"
        @hidden="resetModalAddVehicule"
        hide-footer
        centered
      >
        <!-- form start -->
        <b-form
          class="mt-1"
          ref="addForm"
          @submit.stop.prevent="addHandleSubmit"
        >
          <b-row>
            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="numero_chassis"
                rules="required"
              >
                <label for="numero_chassis">Numero chassis</label>
                <b-form-input
                  id="numero_chassis"
                  type="text"
                  v-model="addVehiculeForm.numero_chassis"
                  placeholder="Numero chassis"
                  @click="resetInputNumChassisModalAdd"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsNumChassisModalAdd
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="marque"
                rules="required"
              >
                <label for="marque">Marques</label>
                <combo-box-component
                id="marque"
                :dataSource="marquesList"
                v-model="addVehiculeForm.marque"
                :state="errors.length > 0 ? false : null"
                >
                </combo-box-component>

                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsMarqueModalAdd
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="couleur"
                rules="required"
              >
                <label for="couleur">Couleur</label>
                <combo-box-component
                id="couleur"
                :dataSource="couleursList"
                v-model="addVehiculeForm.couleur"
                :state="errors.length > 0 ? false : null"
                >
                </combo-box-component>

                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsCouleurModalAdd
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="annee"
                rules="required"
              >
                <label for="annee">Annee</label>
                <b-form-input
                  id="annee"
                  type="text"
                  v-model="addVehiculeForm.annee"
                  placeholder="Annee"
                  @click="resetInputAnneeModalAdd"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsAnneeModalAdd
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="boite"
                rules="required"
              >
                <label for="boite">Boîte</label>
                <b-form-select
                  id="boite_vitesse"
                  v-model="addVehiculeForm.boite"
                  :options="boite_vitesses"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="cylindre"
                rules="required"
              >
                <label for="cylindre">Cylindre</label>
                <b-form-select
                  id="boite_vitesse"
                  v-model="addVehiculeForm.cylindre"
                  :options="cylindres"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="prix_achat"
                rules="required"
              >
                <label for="prix_achat">Prix d'achat</label>
                <b-input-group :append="manifeste.dev_achat">
                  <cleave
                    class="form-control"
                    :raw="false"
                    id="prix_achat"
                    placeholder="0"
                    :options="numberFormat"
                    v-model="addVehiculeForm.prix_achat"
                    @click="resetInputPrixAchatModalAdd"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsPrixAchatModalAdd
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="prix_transport"
                rules="required"
              >
                <label for="prix_transport">Prix de transport</label>
                <b-input-group :append="manifeste.dev_transport">
                  <cleave
                    class="form-control"
                    :raw="false"
                    id="prix_transport"
                    placeholder="0"
                    :options="numberFormat"
                    v-model="addVehiculeForm.prix_transport"
                    @click="resetInputPrixTransportModalAdd"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsPrixTransportModalAdd
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="prix_carte_imp"
                rules="required"
              >
                <label for="prix_carte_imp">Prix carte importateur</label>
                <b-input-group :append="manifeste.dev_carte_imp">
                  <cleave
                    class="form-control"
                    :raw="false"
                    id="prix_carte_imp"
                    :options="numberFormat"
                    placeholder="0"
                    v-model="addVehiculeForm.prix_carte_imp"
                    @click="resetInputPrixCarteImpModalAdd"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsPrixCarteImpModalAdd
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                #default="{ errors }"
                name="frais"
                rules="required"
              >
                <label for="frais">Frais</label>
                <b-input-group :append="manifeste.dev_frais">
                  <cleave
                    class="form-control"
                    :raw="false"
                    id="frais"
                    placeholder="0"
                    :options="numberFormat"
                    v-model="addVehiculeForm.frais"
                    @click="resetInputFraisModalAdd"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsFraisModalAdd
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
          <hr />
          <b-row>
            <b-col>
              <b-button block variant="secondary">
                PRIX DE REVIENT : {{ $thousandSeparator(prixRevientItem) }}
              </b-button>
            </b-col>
          </b-row>

          <b-form-group class="mb-0">
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="modalAddVehicule = false"
            >
              Annuler
            </b-button>

            <b-button
              v-if="etatButtonAddCar"
              variant="primary"
              class="mt-2"
              @click="addHandleOk"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </b-form-group>
        </b-form>
        <!-- form end -->
      </b-modal>
      <!-- modal add vehicule end-->
    </div>
    <b-button
        @click="$router.go(-1)"
        variant="outline-warning"
        class="btn-icon my-1"
        size="sm"
    >
      <span class="">RETOUR</span>
    </b-button>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { ModelListSelect } from "vue-search-select";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import Cleave from "vue-cleave-component";
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BCardTitle,
  BFormDatalist,
  BForm,
  BModal,
  BBadge,
  BButton,
  BIconPlus,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BInputGroup,
  BFormCheckbox,
  BFormDatepicker,
  BIconCircleFill,
  BIconBuilding,
  BIconTruck,
  BIconPeople,
  BIconLayers,
  BIconOption,
  BIconFile,
  BTooltip,
  BIconCheck,
  BIconPencil,
  BIconCheckCircleFill,
  BIconPrinter,
  BIconCalendarEvent,
  BIconArrowClockwise,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import {
  ComboBoxComponent,
  ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";
export default {
  components: {
    VueSweetalert2,
  ComboBoxComponent,
  ComboBoxPlugin,
    VueGoodTable,
    BCardCode,
    BFormDatalist,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    Cleave,
    BIconPlus,
    BRow,
    ModelListSelect,
    BCol,
    BCard,
    BBadge,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BCardTitle,
    BTooltip,
    BFormDatepicker,
    BIconCircleFill,
    BIconBuilding,
    BIconTruck,
    BIconPeople,
    BIconLayers,
    BIconOption,
    BIconFile,
    BIconPencil,
    BIconCheck,
    BIconPrinter,
    BIconCheckCircleFill,
    BIconCalendarEvent,
    BFormSelectOption,
    BIconFileEarmarkArrowDownFill,
    ValidationProvider,
    BIconArrowClockwise,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  data() {
    return {
      etatButtonArrivee: true,
      etatButtonAddCar: true,
      etatButtonEditCar: true,
      etatButtonEditManifeste: true,
      dateFormatOptions: { day: "numeric", month: "long", year: "numeric" },
      valueArrival: "",
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      perPage: 10,
      currentPage: 1,
      filter: "",
      req: false,
      modalEditVehicule: false,
      modalAddVehicule: false,
      modalEditDateArriveeManifeste: false,
      modalEditManifeste: false,
      pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      marquesList: [],
      numberFormat: {
        numeral: true,
        delimiter: " ",
        numeralThousandsGroupStyle: "thousand",
      },
      couleursList: [],
      societesList: [],
      associationsList: [],
      associes_acheteurList: [],
      comptesList: [],
      accounts: [],
      naviresList: [],
      chargeursList: [],
      devises: [],
      manifeste: [],
      devAchat:0,
      devFrais:0,
      devImpo:0,
      devTransp:0,
      vehicules: [],
      boite_vitesses: ["Manuel", "Automatique"],
      cylindres: ["V4", "V6", "V8", "V12"],
      nameState: null,
      headers: [
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          class: "text-center",
        },
        {
          key: "numero",
          label: "N°",
          sortable: true,
          class: "text-bold",
        },
        {
          key: "description",
          label: "Description",
          class: "text-bold",
        },
        {
          key: "numero_chassis",
          label: "Chassis",
          sortable: true,
        },
        {
          key: "prix_achat",
          label: "Achat",
          sortable: true,
        },
        {
          key: "prix_transport",
          label: "Transport",
          sortable: true,
        },
        {
          key: "prix_carte_imp",
          label: "CI",
          sortable: true,
        },
        {
          key: "frais",
          label: "Frais",
          sortable: true,
          // class: 'text-center w-25'
        },
        {
          key: "total_achat",
          label: "Total achat",
          sortable: true,
          // class: 'text-center w-25'
        },
        {
          key: "total_dep",
          label: "Dépenses",
          sortable: true,
          // class: 'text-center w-25'
        },
        {
          key: "prix_revient",
          label: "P. revient",
          sortable: true,
          // class: 'text-center w-25'
        },
        {
          key: "prix_vente",
          label: "P. vente final",
          sortable: true,
          // class: 'text-center w-25'
        },
        {
          key: "benefice",
          label: "Bénéfice",
          sortable: true,
          // class: 'text-center w-25'
        },
        {
          key: "statut",
          label: "Statut",
          sortable: true,
          // class: 'text-center w-25'
        },
      ],
      fields: [
        {
          key: "libelle",
          label: " ",
        },
        {
          key: "montant",
          label: "Montant",
        },
        { key: "conversion", label: "Conversion" },
      ],
      items: [],
      addVehiculeForm: {
        numero_chassis: "",
        marque: "",
        couleur: "",
        boite: "Manuel",
        cylindre: "V4",
        annee: "",
        prix_achat: 0,
        taux_achat: 0,
        taux_transp: 0,
        taux_imp: 0,
        taux_frais: 0,
        prix_transport: 0,
        prix_carte_imp: 0,
        frais: 0,
        manifeste_id: 0,
      },
      editVehiculeForm: {
        id: 0,
        numero_chassis: "",
        marque: "",
        couleur: "",
        annee: "",
        prix_achat: "",
        taux_achat: 0,
        taux_transp: 0,
        taux_imp: 0,
        taux_frais: 0,
        prix_carte_imp: "",
        frais: "",
      },
      editManifesteForm: {
        id: "",
        societeField: "",
        associationField: "",
        associeField: "",
        commissionField: "",
        num_manifeste: "",
        num_voyage: "",
        taux_achat: 0,
        taux_transp: 0,
        taux_imp: 0,
        taux_frais: 0,
        compteField: "",
        navireField: "",
        chargeurField: "",
      },
      editedDate: {
        id: "",
        date_arrivee: "",
      },
      errorsNumChassisModalAdd: null,
      errorsMarqueModalAdd: null,
      errorsCouleurModalAdd: null,
      errorsAnneeModalAdd: null,
      errorsPrixAchatModalAdd: null,
      errorsPrixTransportModalAdd: null,
      errorsPrixCarteImpModalAdd: null,
      errorsFraisModalAdd: null,
      errorsNumChassisModalEdit: null,
      isOperating: false,
      errorsMarqueModalEdit: null,
      errorsCouleurModalEdit: null,
      errorsAnneeModalEdit: null,
      errorsPrixAchatModalEdit: null,
      errorsPrixTransportModalEdit: null,
      errorsPrixCarteImpModalEdit: null,
      errorsFraisModalEdit: null,
      errorsSocieteModalEditManifeste: null,
      errorsAssociationModalEditManifeste: null,
      errorsCompteModalEditManifeste: null,
      errorsAssociesAcheteurModalEditManifeste: null,
      errorsCommissionModalEditManifeste: null,
      errorsNavireModalEditManifeste: null,
      errorsChargeurModalEditManifeste: null,
      errorsDateArriveeModalAdd: null,
      nbr_voitures_vendues: 0,
      compte: {},
      chargeurs: [],
      associe: {},
      acheteur: {},
      association: {},
    };
  },
  mounted() {
    this.getMarques();
    this.getCouleurs();
    this.getSocietesList();
    this.getAssociationsList();
    //this.getAssociesAcheteurList()
    this.getNaviresList();
    this.getChargeursList();
    this.getDevises();
  },
  computed: {
    prixRevientItem() {
      return (
        this.$thousandFormater(this.addVehiculeForm.prix_achat) *
          this.devAchat +
        this.$thousandFormater(this.addVehiculeForm.prix_carte_imp) *
          this.devImpo +
        this.$thousandFormater(this.addVehiculeForm.prix_transport) *
          this.devTransp +
        this.$thousandFormater(this.addVehiculeForm.frais) *
          this.devFrais
      );
    },
    deleteManifeste(element) {
      this.$bvModal
          .msgBoxConfirm("Voulez vous vraiment supprimer cet élement ?", {
            title: "Supprimer le manifeste",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value === true) {
              this.$http
                  .delete(`/manifestes/${element.manifeste.id}`)
                  .then((result) => {
                    this.showToast("Suppression effectuée avec succès", "success");
                    const router = this.$router
                    router.go(-1)
                  })
                  .catch((err) => {
                    this.showToast(err.response.data.data.suppression, "danger");
                  });
            }
          });
    },

    deviseAchat() {
      let devise = this.devises.find(
        (_dev) => _dev.abbreviation === this.manifeste.dev_achat
      );
      return devise ? devise : { value: 0 };
    },
    deviseTransport() {
      let devise = this.devises.find(
        (_dev) => _dev.abbreviation === this.manifeste.dev_transport
      );
      return devise ? devise : { value: 0 };
    },
    deviseCarte() {
      let devise = this.devises.find(
        (_dev) => _dev.abbreviation === this.manifeste.dev_carte_imp
      );
      return devise ? devise : { value: 0 };
    },
    deviseFrais() {
      let devise = this.devises.find(
        (_dev) => _dev.abbreviation === this.manifeste.dev_frais
      );
      return devise ? devise : { value: 0 };
    },
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);
      return maxDate;
    },
    ...mapState({
      user: (state) => state.user.auth,
    }),
    checkManifesteBenefice() {
      let b = 0;
      this.vehicules.forEach((element) => {
        if (element.statut == "vendu" || element.statut == "en cours de vente" || element.statut == "livré"){
          b += element.benefice;
        }


      });

      return b;
    },
    totalRows() {
      // Set the initial number of items
      return this.vehicules.length;
    },
    totalAchats() {
      let total_achat = 0;
      if (this.vehicules) {
        this.vehicules.forEach((vehicule) => {
          total_achat += vehicule.total_achat;
        });
      }
      return total_achat;
    },
    totalPrixVente() {
      let total_dep = 0;
      if (this.vehicules) {
        this.vehicules.forEach((vehicule) => {
          total_dep += vehicule.prix_vente;
        });
      }
      return total_dep;
    },
  },
  created() {
    this.hiddenLoader = true;
    this.getManifeste();
    this.getAccounts();
  },
  methods: {
    showAssociation(item) {
      window.sessionStorage.setItem("association_id", item.id);
      localStorage.setItem("association_name", item.name);
      this.$router.push({
        name: "associations-show",
        params: {
          id: item.id,
          association_id: item.id,
          association_name: item.name,
        },
      });
    },
    updateTaux(){
      this.editVehiculeForm.taux_achat=this.devAchat;
      this.editVehiculeForm.taux_import=this.devImpo;
      this.editVehiculeForm.taux_transp=this.devTransp;
      this.editVehiculeForm.taux_frais=this.devFrais;
      this.editManifesteForm.taux_achat=this.devAchat;
      this.editManifesteForm.taux_import=this.devImpo;
      this.editManifesteForm.taux_transp=this.devTransp;
      this.editManifesteForm.taux_frais=this.devFrais;
    },

    showChargeur(item) {
      if (item) {
        let account = this.accounts.find((_acc) => _acc.libelle === item.name);
        window.sessionStorage.setItem("compte_id", account.id);
        this.$router.push({
          name: "compte-details",
        });
      } else {
        this.showToast(
          "Aucun chargeur n'est enregistré avec ce manifeste",
          "danger"
        );
      }
    },

    showCompte(item) {
      if (item) {
        window.sessionStorage.setItem("compte_id", item.id);
        this.$router.push({
          name: "compte-details",
        });
      } else {
        this.showToast(
          "Aucun compte n'est enregistré avec ce manifeste",
          "danger"
        );
      }
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    showItem(item) {
      this.$router.push({
        name: "vehicules-details",
        params: { id: item.id },
      });
    },
    index(item) {
      return this.vehicules.indexOf(item) + 1;
    },
    getManifeste() {
      let data = JSON.parse(window.sessionStorage.getItem("manifeste_data"));
      this.$http.get("/manifestes/" + data.id).then((result) => {
        this.manifeste = result.data.data;
        this.vehicules = this.manifeste.vehicules;
        if (this.manifeste.dev_achat==="XOF"){
          this.devAchat=1;
        }else {
          this.devAchat=this.manifeste.conv_achat/this.manifeste.prix_achat;
        }
        if (this.manifeste.dev_transport==="XOF"){
          this.devTransp=1;
        }else {
          this.devTransp=this.manifeste.conv_transport/this.manifeste.prix_transport;
        }
        if (this.manifeste.dev_carte_imp==="XOF"){
          this.devImpo=1;
        }else {
          this.devImpo=this.manifeste.conv_carte_imp/this.manifeste.prix_carte_imp;
        }
        if (this.manifeste.dev_frais==="XOF"){
          this.devFrais=1;
        }else {
          console.log(this.manifeste)
          this.devFrais=this.manifeste.conv_frais/this.manifeste.frais;
        }
        //console.log(this.manifeste)
        if (result.status) {
          this.hiddenLoader = true;
        }
        this.vehiculesData();
      });
    },

    vehiculesData() {
      //let data = JSON.parse(window.sessionStorage.getItem('manifeste_data'))
      if (this.vehicules.length) {
        this.hidden = false;
        this.hiddenEmpty = true;
      } else {
        this.hidden = true;
        this.hiddenEmpty = false;
      }
      this.manifeste.total_depenses = 0;
      this.manifeste.total_demarcheurs = 0;
      this.isloading = false;
      this.vehicules.forEach((element) => {
        let total_dep = 0;
        let total_dem = 0;
        if (element.maintenances) {
          element.maintenances.forEach((mtnace) => {
            console.log(mtnace.type)
            if (mtnace.type !== "Paiement Facture normalisée" && mtnace.type !== "Paiement démarcheur") {
              total_dep += mtnace.prix;
            }else if(mtnace.type === "Paiement démarcheur")
            {
              total_dem += mtnace.prix;
            }
          });
          if (element.detail_vente) {
            element.caution = element.detail_vente.caution;
          }
        }
        element.total_dep = total_dep;
        element.total_dem = total_dem;
        console.log(total_dep)
        console.log(total_dem)
        element.prix_revient -= total_dem;
        element.total_achat =
          element.conv_achat +
          element.conv_transport +
          element.conv_frais +
          element.conv_carte_imp;
        //Ajouter les depenses du vehicule aux depenses du manifeste
        this.manifeste.total_depenses += element.total_dep;
        this.manifeste.total_demarcheurs += element.total_dem;
        if (
          element.statut == "vendu" ||
          element.statut == "en cours de vente" ||
          element.statut == "livré"
        )
          this.nbr_voitures_vendues++;
      });
      // Une fois que c'est fait, on calcule à nouveau les prix de vente des vehicules

      this.vehicules.forEach((element) => {
        let a = element.prix_vente + element.caution - element.total_dem  - element.prix_generation_facture;
        element.prix_vente = a;

        element.benefice = element.prix_vente - element.prix_revient;
      });
    },
    getAssociationsList() {
      this.$http
        .get("/associations")
        .then((res) => {
          this.associationsList = res.data.data;
        })
        .catch((e) => {});
    },

    getMarques() {
      this.$http.get("/marques").then((res) => {
        this.marquesList = res.data.data;
        this.marquesList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getCouleurs() {
      this.$http.get("/couleurs").then((res) => {
        this.couleursList = res.data.data;
        this.couleursList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getSocietesList() {
      this.$http.get("/societes").then((res) => {
        this.societesList = res.data.data;
        if (this.societesList.length == 1) {
          this.editManifesteForm.societe = this.societesList[0].id;
        }
      });
    },

    getNaviresList() {
      this.$http.get("/navires").then((res) => {
        this.naviresList = res.data.data;
        this.naviresList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getChargeursList() {
      this.$http.get("/chargeurs").then((res) => {
        this.chargeursList = res.data.data;
        this.chargeurs=[];
        this.chargeursList.filter((chargeur) => {
          chargeur.associations.filter((asso)=>{
            if (asso.id === this.manifeste.association_id)
            {
              this.chargeurs.push(chargeur);
              return  this.editManifesteForm.chargeurField=chargeur.id;
            }
          })

        });
      });
    },

    getDevises() {
      this.$http.get("/devises").then((res) => {
        this.devises = res.data.data;
      });
    },

    // add vehicule start
    addVehicule() {
      let data = JSON.parse(window.sessionStorage.getItem("manifeste_data"));

      this.addVehiculeForm.manifeste_id = data.id;
      // this.modalAddVehicule = true
    },

    addCheckFormValidity() {
      const valid = this.$refs.addForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    addHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.addHandleSubmit();
    },

    addHandleSubmit() {
      if (!this.addCheckFormValidity()) {
        return;
      }

      this.addVehiculeForm.prix_achat = this.$thousandFormater(
        this.addVehiculeForm.prix_achat
      );
      this.addVehiculeForm.prix_transport = this.$thousandFormater(
        this.addVehiculeForm.prix_transport
      );
      this.addVehiculeForm.prix_carte_imp = this.$thousandFormater(
        this.addVehiculeForm.prix_carte_imp
      );
      this.addVehiculeForm.frais = this.$thousandFormater(
        this.addVehiculeForm.frais
      );
      this.isOperating = true;
      this.addVehiculeForm.taux_achat=this.devAchat;
      this.addVehiculeForm.taux_import=this.devImpo;
      this.addVehiculeForm.taux_transp=this.devTransp;
      this.addVehiculeForm.taux_frais=this.devFrais;
      this.etatButtonAddCar = false;

      this.$http
        .post("/ajouter-vehicule", this.addVehiculeForm)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success) {
            this.etatButtonAddCar = true;
            this.req = true;
            this.addVehiculeForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalAddVehicule = false;
            this.showToast("Véhicule enregistré avec succès", "success");
            this.getMarques()
            this.getCouleurs()
            this.getManifeste();
            //this.getVehicule()
            this.req = false;
          } else {
            this.etatButtonAddCar = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonAddCar = true;
          this.isOperating = false;
          var err = e.response.data.message;

          if (err) {
            if (err.numero_chassis) {
              this.errorsNumChassisModalAdd = err.numero_chassis[0];
            }

            if (err.marque) {
              this.errorsMarqueModalAdd = err.marque[0];
            }

            if (err.couleur) {
              this.errorsCouleurModalAdd = err.couleur[0];
            }

            if (err.annee) {
              this.errorsAnneeModalAdd = err.annee[0];
            }

            if (err.prix_achat) {
              this.errorsPrixAchatModalAdd = err.prix_achat[0];
            }

            if (err.prix_transport) {
              this.errorsPrixTransportModalAdd = err.prix_transport[0];
            }

            if (err.prix_carte_imp) {
              this.errorsPrixCarteImpModalAdd = err.prix_carte_imp[0];
            }

            if (err.frais) {
              this.errorsFraisModalAdd = err.frais[0];
            }
          }
        });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    resetModalAddVehicule() {
      this.addVehiculeForm.numero_chassis = "";
      this.addVehiculeForm.marque = "";
      this.addVehiculeForm.couleur = "";
      this.addVehiculeForm.annee = "";
      this.addVehiculeForm.prix_achat = "";
      this.addVehiculeForm.prix_transport = "";
      this.addVehiculeForm.prix_carte_imp = "";
      this.addVehiculeForm.frais = "";
      this.nameState = null;
      this.resetModalAddInput();
    },

    resetModalAddInput() {
      this.resetInputNumChassisModalAdd();
      this.resetInputMarqueModalAdd();
      this.resetInputCouleurModalAdd();
      this.resetInputAnneeModalAdd();
      this.resetInputPrixAchatModalAdd();
      this.resetInputPrixTransportModalAdd();
      this.resetInputPrixCarteImpModalAdd();
    },

    resetInputNumChassisModalAdd() {
      if (this.errorsNumChassisModalAdd) {
        this.errorsNumChassisModalAdd = null;
      }
    },

    resetInputMarqueModalAdd() {
      if (this.errorsMarqueModalAdd) {
        this.errorsMarqueModalAdd = null;
      }
    },

    resetInputCouleurModalAdd() {
      if (this.errorsCouleurModalAdd) {
        this.errorsCouleurModalAdd = null;
      }
    },

    resetInputAnneeModalAdd() {
      if (this.errorsAnneeModalAdd) {
        this.errorsAnneeModalAdd = null;
      }
    },

    resetInputPrixAchatModalAdd() {
      if (this.errorsPrixAchatModalAdd) {
        this.errorsPrixAchatModalAdd = null;
      }
    },

    resetInputPrixTransportModalAdd() {
      if (this.errorsPrixTransportModalAdd) {
        this.errorsPrixTransportModalAdd = null;
      }
    },

    resetInputPrixCarteImpModalAdd() {
      if (this.errorsPrixCarteImpModalAdd) {
        this.errorsPrixCarteImpModalAdd = null;
      }
    },

    resetInputFraisModalAdd() {
      if (this.errorsFraisModalAdd) {
        this.errorsFraisModalAdd = null;
      }
    },
    // add vehicule end

    // Edit vehicule start
    editVehicule(item) {
      this.editVehiculeForm.id = item.id;
      this.editVehiculeForm.numero_chassis = item.numero_chassis;
      this.editVehiculeForm.annee = item.annee;
      this.editVehiculeForm.prix_achat = item.prix_achat;
      this.editVehiculeForm.prix_transport = item.prix_transport;
      this.editVehiculeForm.prix_carte_imp = item.prix_carte_imp;
      this.editVehiculeForm.frais = item.frais;
      this.editVehiculeForm.taux_achat=this.devAchat;
      this.editVehiculeForm.taux_import=this.devImpo;
      this.editVehiculeForm.taux_transp=this.devTransp;
      this.editVehiculeForm.taux_frais=this.devFrais;

      for (let i = 0; i < this.marquesList.length; i++) {
        if (this.marquesList[i].name == item.marque) {
          this.editVehiculeForm.marque = this.marquesList[i].id;
          i = this.marquesList.length;
        }
      }

      for (let i = 0; i < this.couleursList.length; i++) {
        if (this.couleursList[i].name == item.couleur) {
          this.editVehiculeForm.couleur = this.couleursList[i].id;
          i = this.couleursList.length;
        }
      }

      this.modalEditVehicule = true;
    },

    editCheckFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    editHandleSubmit() {
      if (!this.editCheckFormValidity()) {
        return;
      }
      this.editVehiculeForm.prix_achat = this.$thousandFormater(
        this.editVehiculeForm.prix_achat
      );
      this.editVehiculeForm.prix_transport = this.$thousandFormater(
        this.editVehiculeForm.prix_transport
      );
      this.editVehiculeForm.prix_carte_imp = this.$thousandFormater(
        this.editVehiculeForm.prix_carte_imp
      );
      this.editVehiculeForm.frais = this.$thousandFormater(
        this.editVehiculeForm.frais
      );
      this.editVehiculeForm.taux_achat=this.devAchat;
      this.editVehiculeForm.taux_import=this.devImpo;
      this.editVehiculeForm.taux_transp=this.devTransp;
      this.editVehiculeForm.taux_frais=this.devFrais;
      this.isOperating = true;
      this.etatButtonEditCar = false;
      console.log("Vehicule",this.editVehiculeForm)

      this.$http
        .put("/manifestes/" + this.editVehiculeForm.id, this.editVehiculeForm)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success) {
            this.etatButtonEditCar = true;
            this.req = true;
            this.editVehiculeForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalEditVehicule = false;
            this.showToast("Véhicule modifié avec succès", "success");
            this.getManifeste();
            this.req = false;
          } else {
            this.etatButtonEditCar = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonEditCar = true;
          this.isOperating = false;

          var err = e.response.data.message;

          if (err.numero_chassis) {
            this.errorsNumChassisModalEdit = err.numero_chassis[0];
          }

          if (err.marque) {
            this.errorsMarqueModalEdit = err.marque[0];
          }

          if (err.couleur) {
            this.errorsCouleurModalEdit = err.couleur[0];
          }

          if (err.annee) {
            this.errorsAnneeModalEdit = err.annee[0];
          }

          if (err.prix_achat) {
            this.errorsPrixAchatModalEdit = err.prix_achat[0];
          }

          if (err.prix_transport) {
            this.errorsPrixTransportModalEdit = err.prix_transport[0];
          }

          if (err.prix_carte_imp) {
            this.errorsPrixCarteImpModalEdit = err.prix_carte_imp[0];
          }

          if (err.frais) {
            this.errorsFraisModalEdit = err.frais[0];
          }
        });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    editHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.editHandleSubmit();
    },

    resetModalEditVehicule() {
      this.editVehiculeForm.id = 0;
      this.editVehiculeForm.numero_chassis = "";
      this.editVehiculeForm.marque = "";
      this.editVehiculeForm.couleur = "";
      this.editVehiculeForm.annee = "";
      this.editVehiculeForm.prix_achat = "";
      this.editVehiculeForm.prix_transport = "";
      this.editVehiculeForm.prix_carte_imp = "";
      this.editVehiculeForm.frais = "";
      this.nameState = null;
      this.resetModalEditInput();
    },

    resetModalEditInput() {
      this.resetInputNumChassisModalEdit();
      this.resetInputMarqueModalEdit();
      this.resetInputCouleurModalEdit();
      this.resetInputAnneeModalEdit();
      this.resetInputPrixAchatModalEdit();
      this.resetInputPrixTransportModalEdit();
      this.resetInputPrixCarteImpModalEdit();
      this.resetInputFraisModalEdit();
    },

    resetInputNumChassisModalEdit() {
      if (this.errorsNumChassisModalEdit) {
        this.errorsNumChassisModalEdit = null;
      }
    },

    resetInputMarqueModalEdit() {
      if (this.errorsMarqueModalEdit) {
        this.errorsMarqueModalEdit = null;
      }
    },

    resetInputCouleurModalEdit() {
      if (this.errorsCouleurModalEdit) {
        this.errorsCouleurModalEdit = null;
      }
    },

    resetInputAnneeModalEdit() {
      if (this.errorsAnneeModalEdit) {
        this.errorsAnneeModalEdit = null;
      }
    },

    resetInputPrixAchatModalEdit() {
      if (this.errorsPrixAchatModalEdit) {
        this.errorsPrixAchatModalEdit = null;
      }
    },

    resetInputPrixTransportModalEdit() {
      if (this.errorsPrixTransportModalEdit) {
        this.errorsPrixTransportModalEdit = null;
      }
    },

    resetInputPrixCarteImpModalEdit() {
      if (this.errorsPrixCarteImpModalEdit) {
        this.errorsPrixCarteImpModalEdit = null;
      }
    },

    resetInputFraisModalEdit() {
      if (this.errorsFraisModalEdit) {
        this.errorsFraisModalEdit = null;
      }
    },
    // Edit vehicule end

    // add date d'arrivée start
    editDateArriveeManifeste() {
      let data = JSON.parse(window.sessionStorage.getItem("manifeste_data"));

      this.editedDate.id = data.id;
      this.modalEditDateArriveeManifeste = true;
    },

    checkFormValidity() {
      const valid = this.$refs.addDateForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    addDateHandleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.isOperating = true;
      if (!this.editedDate.date_arrivee) {
        this.errorsDateArriveeModalAdd = "Veuillez sélectionner une date !";
      } else {
        this.etatButtonArrivee = false;
        this.editedDate.taux_achat=this.devAchat
        this.editedDate.taux_transp=this.devTransp
        console.log(this.editedDate)
        this.$http
          .put("/receptionner-manifeste/" + this.editedDate.id, this.editedDate)
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.etatButtonArrivee = true;
              this.req = true;
              this.editedDate = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );
              this.modalEditDateArriveeManifeste = false;
              this.showToast("Manifeste receptionné avec succès", "success");
              this.getManifeste();
              this.req = false;
            } else {
              this.etatButtonArrivee = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonArrivee = true;
            this.isOperating = false;
            var err = e.response.data.errors;

            if (err) {
              if (err.date_arrivee) {
                this.errorsDateArriveeModalAdd = err.date_arrivee[0];
              }
            } else if (e.response.status == 404) {
              this.req = true;
              this.editedDate = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );
              this.modalEditDateArriveeManifeste = false;
              this.showToast("Manifeste receptionné avec succès", "success");
              this.getManifeste();
              this.req = false;
            }
          });
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    addDateHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.addDateHandleSubmit();
    },

    resetModalAddDateArrivee() {
      this.editedDate.date_Arrival = "";
      this.nameState = null;
      this.resetInputDate();
    },

    resetInputDate() {
      if (this.errorsDateArriveeModalAdd) {
        this.errorsDateArriveeModalAdd = null;
      }
    },
    // add date d'arrivée end

    // edit manifeste start
    editManifeste() {
      this.editManifesteForm.id = this.manifeste.id;
      this.editManifesteForm.societeField = this.manifeste.societe_id;
      this.editManifesteForm.associationField = this.manifeste.association_id;
      this.editManifesteForm.associeField = this.manifeste.associe_id;
      this.editManifesteForm.num_manifeste = this.manifeste.num_manifeste;
      this.editManifesteForm.num_voyage = this.manifeste.num_voyage;
      this.editManifesteForm.navireField = this.manifeste.navire_id;
      this.editManifesteForm.chargeurField = this.manifeste.chargeur_id;
      this.editManifesteForm.commissionField =
        this.manifeste.commission_acheteur;
      // this.editManifesteForm.compteField = this.manifeste.dev_achat;
      //this.selectAssociationModalEditManifeste();

      //this.selectAssociesAcheteurModalEditManifeste();

      /* if (this.comptesList > 0) {
        this.editManifesteForm.compteField = this.comptesList.filter(
          (element) => {
            return element["devise-abbreviation"] == this.manifeste.dev_achat;
          }
        )[0].id;
      } */
      this.editManifesteForm.compteField = this.manifeste.compte.id;
      this.modalEditManifeste = true;
    },

    editManifestecheckFormValidity() {
      const valid = this.$refs.editManifesteForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    editManifesteHandleSubmit() {
      if (!this.editManifestecheckFormValidity()) {
        return;
      }
      this.editManifesteForm.commissionField = this.$thousandFormater(
        this.editManifesteForm.commissionField
      );
      this.isOperating = true;

      this.etatButtonEditManifeste = false;
      this.editManifesteForm.taux_achat=this.devAchat;
      this.editManifesteForm.taux_import=this.devImpo;
      this.editManifesteForm.taux_transp=this.devTransp;
      this.editManifesteForm.taux_frais=this.devFrais;
      console.log("Manifest",this.editManifesteForm)
      this.$http
        .put(
          "/update-manifeste/" + this.editManifesteForm.id,
          this.editManifesteForm
        )
        .then((result) => {
          this.isOperating = false;
          if (result.data.success) {
            this.etatButtonEditManifeste = true;
            this.req = true;
            this.editManifesteForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalEditManifeste = false;
            this.showToast("Modification effectuée avec succès", "success");
            this.getManifeste();
            //this.getVehicule()
            this.req = false;
          } else {
            this.etatButtonEditManifeste = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonEditManifeste = true;
          this.isOperating = false;
          var err = e.response.data.message;

          if (err.societeField) {
            this.errorsSocieteModalEditManifeste = err.societeField[0];
          }
          if (err.associationField) {
            this.errorsAssociationModalEditManifeste = err.associationField[0];
          }

          if (err.associe_acheteur) {
            this.errorsAssocie_acheteurModalEditManifeste =
              err.associe_acheteur[0];
          }
          if (err.commissionField) {
            this.errorsCommissionModalEditManifeste = err.commissionField[0];
          }
          if (err.navireField) {
            this.errorsNavireModalEditManifeste = err.navireField[0];
          }

          if (err.chargeurField) {
            this.errorsChargeurModalEditManifeste = err.chargeurField[0];
          }
        });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    editManifesteHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.editManifesteHandleSubmit();
    },

    resetSelectSocieteModalEditManifeste() {
      if (this.errorsSocieteModalEditManifeste) {
        this.errorsSocieteModalEditManifeste = null;
      }
    },
    resetSelectAssociationModalEditManifeste() {
      if (this.errorsAssociation) {
        this.errorsAssociation = null;
      }
    },

    getAccounts() {
      this.$http.get("/comptes").then((response) => {
        this.accounts = response.data.data;
        this.comptesList = this.accounts.filter((compte) => {
          if (
              compte.author_type === "associe" &&
              compte.author_association === this.manifeste.association_id
          ) {
            return true;
          }
        });

      });
    },

    selectAssociationModalEditManifeste() {
      this.association = this.associationsList.filter((element) => {
        return element.id == this.editManifesteForm.associationField;
      })[0];
      this.associes_acheteurList = this.association.acheteurs_associes;
      this.comptesList = this.accounts.filter((compte) => {
        if (
          compte.author_type === "associe" &&
          compte.author_association === this.editManifesteForm.associationField
        ) {
          return  this.editManifesteForm.compteField=compte.id;
        }
      });
      this.chargeurs=[];
      this.chargeursList.filter((chargeur) => {
          chargeur.associations.filter((asso)=>{
            if (asso.id === this.editManifesteForm.associationField)
            {
              this.chargeurs.push(chargeur);
              return  this.editManifesteForm.chargeurField=chargeur.id;
            }
          })

        });

  /*    this.chargeurs = this.chargeursList.filter((chargeur) => {
        if (
            chargeur.user_id === this.editManifesteForm.associationField
        ) {
          return true;
        }
      });*/
      this.associes_acheteurList.filter((element) => {
        if (element.type === "acheteur") {
          this.comptesList.push(element.compte[0]);
        }
      });
    },

    resetSelectAssociesAcheteurModalEditManifeste() {
      if (this.errorsAssociesAcheteurModalEditManifeste) {
        this.errorsAssociesAcheteurModalEditManifeste = null;
      }
    },

    selectCompteModalEditManifeste() {
      this.compte = this.comptesList.filter((element) => {
        return element.id == this.editManifesteForm.compteField;
      })[0];
      //this.changeDeviseAchat()
    },
    resetSelectCompteModalEditManifeste() {
      if (this.errorsCompteModalEditManifeste) {
        this.errorsCompteModalEditManifeste = null;
      }
    },

    resetSelectNavireModalEditManifeste() {
      if (this.errorsNavireModalEditManifeste) {
        this.errorsNavireModalEditManifeste = null;
      }
    },

    resetSelectChargeurModalEditManifeste() {
      if (this.errorsChargeurModalEditManifeste) {
        this.errorsChargeurModalEditManifeste = null;
      }
    },

    resetModalEditManifeste() {
      this.editManifesteForm.societeField = "";
      this.editManifesteForm.associeField = "";
      this.editManifesteForm.navireField = "";
      this.editManifesteForm.commissionField = "";
      this.editManifesteForm.chargeurField = "";
      this.editManifesteForm.compteField = "";
      this.editManifesteForm.num_manifeste = "";
      this.editManifesteForm.num_voyage = "";
      this.nameState = null;
      this.resetSelectSocieteModalEditManifeste();
      this.resetSelectAssociesAcheteurModalEditManifeste();
      this.resetSelectNavireModalEditManifeste();
      this.resetSelectChargeurModalEditManifeste();
    },
    // edit manifeste end

    printManifest() {
      let routeData = this.$router.resolve({
        name: "manifestes-print",
      });
      window.open(routeData.href, "_blank");
    },
    printManifeste() {
      let routeData = this.$router.resolve({
        name: "manifestes-print-id",
        params: { id: this.manifeste.id },
      });
      window.open(routeData.href, "_blank");
    },

    deleteVehicule(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des voitures ?",
          {
            title: "Supprimer une voiture",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/vehicules/" + item.id, item)
              .then((result) => {
                if (result.data.success) {
                  this.req = true;
                  this.showToast(
                    "Suppression effectuée avec succès",
                    "success"
                  );
                  this.getManifeste();
                  //this.getVehicule()
                  this.req = false;
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "danger");
              });
          }
        });
    },
  },
};
</script>

<style>
	.block-text .card-body {
		padding-bottom: 0px;
	}

	#loader {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #f8f8f8;
		padding-top: 15%;
		z-index: 1000;
	}

	.cursor {
		cursor: pointer;
	}

	@media screen and (max-width: 1000px) {
		.search {
			margin-right: 50% !important;
		}

		.block-text-media {
			margin-right: 5% !important;
		}
		
		.element-manifestes {
			width: 200px !important;
		}
	}

	@media screen and (max-width: 820px) {
		.container-informations {
			width: 100% !important;
			margin-right: 20px !important;
		}

		.container-manifeste {
			margin-left: 0px !important;
		}
	}

	@media screen and (max-width: 435px) {
		.container-search {
			width: initial !important;
			margin-right: 0px !important;
		}
	}
</style>